/* istanbul ignore file */

import * as Sentry from "@sentry/angular-ivy";

import { ErrorHandler, Injectable } from "@angular/core";

import { BrowserTracing } from "@sentry/tracing";
import { CaptureContext } from "@sentry/types";
import { User } from "@angular/fire/auth";
import { WindowService } from "@yoimo/joymo-ui";
import { environment } from "@environment";

type BreadcrumbType = "AUTH" | "FIREBASE" | "BACKEND" | "CLIENT" | "SOCIAL";

const { sentryDsn } = environment;

/**
 * Handle error logs/traces.
 * If running on local environment it prints to console, otherwise uses Sentry.
 */
@Injectable({ providedIn: "root" })
export class LoggingService implements ErrorHandler {
  constructor(private windowService: WindowService) {
    Sentry.init({
      dsn: sentryDsn,
      enabled: Boolean(sentryDsn),
      environment: environment.firebase.projectId,
      integrations: [new BrowserTracing()],
      release: "e121428ea9183be8a3f7bc445ebca8bf77a3990c", // Release name must be replaced manually before building the app,
      replaysOnErrorSampleRate: environment.production ? 0.5 : 0,
      tracesSampleRate: environment.production ? 0.5 : 1.0,
      beforeSend: (event, hint) => {
        const { originalException: exception } = hint;

        if (exception instanceof Error) {
          event.fingerprint = [exception.message];
        }

        return event;
      },
    });
  }

  /**
   * Creates an exception event
   *
   * @param error any error or exception
   * @param context Sentry capture context, read Sentry documentation for more information
   * @returns event id for the Sentry event created
   */
  static logError(error: any, context?: CaptureContext): string | undefined {
    // eslint-disable-next-line no-restricted-globals
    if (!environment.production) console.error(error);
    return Sentry.captureException(error, context);
  }

  static setBreadcrumb(
    message: string,
    origin?: BreadcrumbType,
    additionalData?: Record<string, any>
  ): void {
    if (!sentryDsn) {
      // eslint-disable-next-line no-restricted-globals
      console.warn(message, origin, additionalData);
      return;
    }
    Sentry.addBreadcrumb({ message, data: { origin, additionalData } });
  }

  /**
   * @deprecated Should only be used by Angular as a global handler of uncaptured exceptions
   * @see LoggingService.logError
   */
  handleError(error: any): void {
    LoggingService.logError(error);
  }

  static linkToUser(user: User): void {
    if (!user?.email) {
      LoggingService.setBreadcrumb("Logged out");
      Sentry.setUser(null);
      return;
    }
    LoggingService.setBreadcrumb("Logged in");
    Sentry.setUser({ email: user.email });
  }
}
