import { Environment } from "./environment.tpl";

export const environment: Environment = {
  production: true,
  joymo: {
    arenaUrl: "https://arena.joymo.no",
    acceptableUsePolicyUrl: "https://www.joymo.tv/acceptable-use-policy",
    joymoUrl: "https://joymo.tv",
    privacyPolicy: "https://www.joymo.tv/privacy-policy",
    tosUrl: "https://www.joymo.tv/terms-of-service",
  },
  sentryDsn:
    "https://1b412262a393419185c2e199174cc6fd@o1234650.ingest.sentry.io/4504298087448576",
  googleOAuthClientId:
    "102685381919-rid4t2l4jf1veo061ucbk7frtvlgulo2.apps.googleusercontent.com",
  firebase: {
    apiKey: "AIzaSyBbJpFMDN8DpGCPGMyt_D7Ki609eyiCGwY",
    appId: "1:102685381919:web:ff42b2bf44b492aac04c2a",
    authDomain: "auth.joymo.no",
    databaseURL: "https://yoimo-live-production.firebaseio.com",
    measurementId: "G-4T2W9PJWE8",
    messagingSenderId: "102685381919",
    projectId: "yoimo-live-production",
    storageBucket: "yoimo-live-production.appspot.com",
    locationId: "us-central1",
  },
  meta: {
    company: "Joymo",
    baseAppUrl: "https://auth.joymo.no",
    defaultDomain: "auth.joymo.no",
    domainAliases: {},
    defaultBranding: {
      accentColor: "#1ed761",
      backgroundColor: "#333333",
      themeStyle: "rounded",
    },
  },
  behavior: {
    authProviders: {
      google: {
        allowsWebview: false,
        id: "google",
        isEnabled: true,
        name: "Google",
      },
      facebook: {
        allowsWebview: true,
        id: "facebook",
        isEnabled: true,
        name: "Facebook",
      },
    },
    testFeatures: {
      favicons: {
        "0AYlDfFb7OtdRQfjgbQ2": "basketballireland.png",
        "8qRfXG1IprJvJTK6HZNT": "englandnetball.png",
        "9wwo2harPwaUfNXpyTtO": "bucs.png",
        adKRUey5k6UVEH3HsmAG: "judocanada.png",
        DEFAULT: "joymo.png",
        jcuiiq6fmjXY0kAEIfCo: "archery.png",
        LBkaX3u8sJUbg2GpxTo1: "englandhockey.png",
        QaVzbVlrp8deh9ojYgtZ: "tabletennisengland.png",
        qMRVy28NKhtuR4znSccl: "gbbasketball.png",
        yxc7Po2qza2o5ZvJ2ZQY: "usath.png",
        iyhmqq8DaULcNVEyIadi: "wbsceurope.png",
      },
    },
  },
  shareApp: {
    name: "Joymo Share",
    iconUrl: "https://www.joymo.tv/favicon-32x32.png",
    appStore: {
      downloadUrl: "https://apps.apple.com/no/app/joymo-share/id1482394966",
      downloadUrlQrCode: "/assets/share-app/qr-appstore.png",
      marketplaceName: "App Store",
      badgeUrl:
        "https://apple-resources.s3.amazonaws.com/media-badges/download-on-the-app-store/black/en-us.svg",
    },
    playStore: {
      downloadUrl:
        "https://play.google.com/store/apps/details?id=no.joymo.share",
      downloadUrlQrCode: "/assets/share-app/qr-playstore.png",
      marketplaceName: "Play Store",
      badgeUrl: "/assets/share-app/generic_playstore-badge.png",
    },
  },
};
